// import { DE005List, DE009List, DE014List } from './index';

const tombstoneDataElementArray = [
  // {
  //   id: 'DE001',
  //   name: 'Procurement/Requisition Number',
  //   family: 'tombstone',
  //   type: 'String',
  //   defaultValue: '',
  //   placeholder: '',
  //   validation: {
  //     max: {
  //       value: 10,
  //       message: 'Procurement/Requisition Number cannot have more than 10 characters'
  //     }
  //   },
  //   description:
  //     'System generated number and unique identifier for each procurement. It is made of two digit year, four digit sequential number (00-0000).',
  //   hasDescription: true,
  //   isDisplayed: false
  // },
  {
    id: 'DE002',
    name: 'Procurement/Requisition Name*',
    family: 'tombstone',
    type: 'String',
    defaultValue: '',
    placeholder: '22 - 0001',
    isRequired: true,
    validation: {
      isRequired: true,
      max: {
        value: 100,
        message: 'Procurement/Requisition Name cannot have more than 100 characters'
      }
    },
    description:
      'Unique name of the procurement, frequently a combination of the RFx type, service category, and/or project name. For example, Request for Qualifications to Design-Bid-Build New Toronto Island.',
    hasDescription: true,
    isDisplayed: true
  },
  {
    id: 'DE003',
    name: 'Project Name*',
    family: 'tombstone',
    type: 'String',
    defaultValue: '',
    placeholder: '',
    isRequired: true,
    validation: {
      isRequired: true,
      max: {
        value: 100,
        message: 'Project Name cannot have more than 100 characters'
      }
    },
    description: 'Unique name of the project to which the procurement belongs.',
    hasDescription: true,
    isDisplayed: true
  },
  {
    id: 'DE004',
    name: 'Project Number*',
    family: 'tombstone',
    type: 'String',
    defaultValue: '',
    placeholder: '22 - 0001',
    validation: {
      isRequired: true,
      max: {
        value: 100,
        message: 'Project Number cannot have more than 10 characters'
      }
    },
    description:
      'Unique identifier of the project that the procurement belongs to. Procurements without projects should have their financial cost centre recorded as their project number. Procurements tied to multiple projects must have each project listed as comma separated values, e.g. "098-XYZ, 099-ABC". The project number is frequently cited within Section 1A of PCAR1.',
    hasDescription: true,
    isDisplayed: true
  },
  {
    id: 'DE009',
    name: 'Service Type',
    family: 'tombstone',
    type: 'Enumerated',
    defaultValue: '',
    placeholder: '',
    validation: {},
    // list: [],
    description:
      'Type of service being requested. Second-stages are to use the name of the service category per their first-stage procurement. Ex. Architectural & Interior Design Services.',
    hasDescription: true,
    isDisplayed: true
  },
  {
    id: 'DE005',
    name: 'Procurement/Requisition RFx Type',
    family: 'tombstone',
    type: 'Enumerated',
    defaultValue: '',
    placeholder: '',
    validation: {},
    // list: [],
    description: 'Type of front-end document used during the procurement.',
    hasDescription: true,
    isDisplayed: true
  },
  {
    id: 'DE014',
    name: 'Procurement/Requisition Method ',
    family: 'tombstone',
    type: 'Enumerated',
    defaultValue: '',
    placeholder: '',
    validation: {},
    // list: [],
    description: 'The procurement method as per the Procurement Policy.',
    hasDescription: true,
    isDisplayed: true
  },
  {
    id: 'DE015',
    name: 'Technical Evaluation',
    family: 'tombstone',
    type: 'Binary',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description: 'Flag whether the procurement includes technical evaluations.',
    hasDescription: true,
    isDisplayed: true
  },
  {
    id: 'DE016',
    name: 'Financial Evaluation',
    family: 'tombstone',
    type: 'Binary',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description: 'Flag whether the procurement includes financial evaluations.',
    hasDescription: true,
    isDisplayed: true
  },
  {
    id: 'DE212',
    name: 'Materially Complex (Y/N)',
    family: 'tombstone',
    type: 'Binary',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description: `Flag whether the procurement is materially complex. Such procurement may involve non-templated documents, non-templated processes, and/or multi-staged technical evaluations.`,
    hasDescription: true,
    isDisplayed: true
  },
  {
    id: 'DE006',
    name: 'Business Unit',
    family: 'tombstone',
    type: 'Enumerated',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description:
      "The business unit within IO making the request for procurement (Internal Requesting Business Unit). Frequently cited within the 'Dept.' field of PCAR1's Section 1A, or equivalent.",
    hasDescription: true,
    isDisplayed: true
  },
  {
    id: 'DE007',
    name: 'Business Unit Lead',
    family: 'tombstone',
    type: 'String',
    defaultValue: '',
    placeholder: '',
    validation: {
      max: {
        value: 100,
        message: 'Business Unit Lead cannot have more than 100 characters'
      }
    },
    description: "Name of requesting Business Unit's lead personnel on the procurement.",
    hasDescription: true,
    isDisplayed: true
  },
  {
    id: 'DE019',
    name: 'Programs and Practice Areas',
    family: 'tombstone',
    type: 'Enumerated',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description: 'The Program & Practice Area to which the procurement belongs.',
    hasDescription: true,
    isDisplayed: true
  },
  {
    id: 'DE020',
    name: 'Portfolio',
    family: 'tombstone',
    type: 'Enumerated',
    defaultValue: '',
    placeholder: '',
    // list: [],
    validation: {},
    description: 'The Portfolio to which the procurement belongs.',
    hasDescription: true,
    isDisplayed: true
  },
  {
    id: 'DE008',
    name: 'Partner Ministry',
    family: 'tombstone',
    type: 'Enumerated',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description:
      'Ministry within Ontario Government sponsoring or co-sponsoring the project, or equivalent.',
    hasDescription: true,
    isDisplayed: true
  },
  {
    id: 'DE012',
    name: 'Procurement/Requisition Value',
    family: 'tombstone',
    type: 'Currency',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description:
      'The estimated pre-value of the procurement or requisition, as found within Section 1D or PCAR1, or equivalent.',
    hasDescription: true,
    isDisplayed: true
  },
  {
    id: 'DE013',
    name: 'Record File Creation Date',
    family: 'tombstone',
    type: 'date',
    defaultValue: 'new Date()',
    placeholder: '',
    validation: {
      min: {
        value: 'new Date()',
        message: "Date can't be before current date"
      }
    },
    description: "The date on which the procurement or requisition's record file was created.",
    hasDescription: true,
    isDisplayed: true
  },

  {
    id: 'DE017',
    name: 'Record File Location',
    family: 'tombstone',
    type: 'String',
    defaultValue: '',
    placeholder: '',
    validation: {
      max: {
        value: 500,
        message: 'Record File Location cannot have more than 100 characters'
      }
    },
    description: "The URL for the procurement's record file.",
    hasDescription: true,
    isDisplayed: true
  },
  {
    id: 'DE018',
    name: 'Associated Procurement Number(s)',
    family: 'tombstone',
    type: 'String',
    defaultValue: '',
    placeholder: '',
    validation: {
      max: {
        value: 10,
        message: 'Associated Procurement Number(s) cannot have more than 10 characters'
      }
    },
    description:
      "In the event that this procurement is a Second-stage, enter the associated First-stage’s Procurement Number. Otherwise leave blank. For example, Call-ups should have their Request for Standing Offer's Procurement Number cited.",
    hasDescription: true,
    isDisplayed: true
  },
  {
    id: 'DE010',
    name: 'Description',
    family: 'tombstone',
    type: 'String',
    defaultValue: '',
    placeholder: '',
    validation: {
      max: {
        value: 1000,
        message: 'Description cannot have more than 1000 characters'
      }
    },
    description: 'A brief summary/description about the procurement',
    hasDescription: true,
    isDisplayed: true
  },
  {
    id: 'DE011',
    name: 'Notes',
    family: 'tombstone',
    type: 'String',
    defaultValue: '',
    placeholder: '',
    validation: {
      max: {
        value: 1000,
        message: 'Notes cannot have more than 1000 characters'
      }
    },
    description:
      'Notes as required; this may include rationales for project/procurement cancellation, delays, changes to delivery model and/or procurement strategy, etc.',
    hasDescription: true,
    isDisplayed: true
  },
  {
    id: 'DE244',
    name: 'File Close Out Received Date',
    family: 'tombstone',
    type: 'date',
    defaultValue: 'new Date()',
    placeholder: '',
    validation: {},
    description:
      'The date on which the File Close Out is received within the Procurement Operations inbox',
    hasDescription: true,
    isDisplayed: true
  },
  {
    id: 'DE245',
    name: 'File Close Out Operations Approved Date',
    family: 'tombstone',
    type: 'date',
    defaultValue: 'new Date()',
    placeholder: '',
    validation: {},
    description:
      'The date on which the Operations personnel signed off on the File Close Out and uploaded a copy to the record file.',
    hasDescription: true,
    isDisplayed: true
  },
  {
    id: 'DE246',
    name: 'File Close Out PDT/PLT Approved Date',
    family: 'tombstone',
    type: 'date',
    defaultValue: 'new Date()',
    placeholder: '',
    validation: {},
    description:
      'The date on which the PDT/PLT personnel signed off on the File Close Out and uploaded a copy to the record file.',
    hasDescription: true,
    isDisplayed: true
  }
];

export default tombstoneDataElementArray;
