import React from 'react';
import { Typography } from '@mui/material';
import { RenderForm } from '../../../utils/helper';
import PropTypes from 'prop-types';
import {
  StyledBoxContainer,
  StyledGridContainer,
  StyledGridItem,
  StyledHeaderBox
} from '../commonStyles/styled';

function Tombstone({ data }) {
  // console.log("let's test my default value object in tombstone",myDefaultValuesObject);
  // console.info('Tombstone data:', data);
  // renderCount++;
  // const [group1, setGroup1] = useState({
  //   ...data,
  //   data: data.data.slice(0, 4)
  //   // data: data.data.slice(0, 2)
  // });
  // const [group2, setGroup2] = useState({
  //   ...data,
  //   data: data.data.slice(4, 9)
  // });
  // const [group3, setGroup3] = useState({
  //   ...data,
  //   data: data.data.slice(9, 14)
  // });
  // const [adminGroup, setAdminGroup] = useState({
  //   ...data,
  //   data: data.data.slice(14)
  // });
  const group1 = {
    ...data,
    data: data.data.slice(0, 3)
  };
  const group2 = {
    ...data,
    data: data.data.slice(3, 9)
  };
  const group3 = {
    ...data,
    data: data.data.slice(9, 14)
  };
  const adminGroup = {
    ...data,
    data: data.data.slice(14, 20)
  };
  const fileCloseOut = {
    ...data,
    data: data.data.slice(20)
  };

  return (
    <>
      {/*<Grid item xs={12} sx={{border:'0.5px solid red'}}>*/}
      {/*  /!*{RenderForm(data)}*!/*/}
      {/*    {<RenderForm formDataConfig={data}/>}*/}
      {/*</Grid>*/}

      {/*<Box sx={{padding: '5px',border:'1px solid red'}}>*/}
      {/*    <Typography variant='h5'>Required for Procurement Creation</Typography>*/}
      {/*</Box>*/}
      <StyledGridContainer container>
        <StyledGridItem item xs={12}>
          {/*<Typography variant="h2">Required for Procurement Creation</Typography>*/}
          <StyledBoxContainer>
            <StyledHeaderBox />
            <Typography variant="h2">Required for Procurement Creation</Typography>
          </StyledBoxContainer>
        </StyledGridItem>
        {<RenderForm formDataConfig={group1} />}
      </StyledGridContainer>

      <StyledGridContainer container>
        <StyledGridItem item xs={12}>
          <StyledBoxContainer>
            <StyledHeaderBox />
            <Typography variant="h2">Required for Procurement Assignment</Typography>
          </StyledBoxContainer>
        </StyledGridItem>
        {<RenderForm formDataConfig={group2} />}
      </StyledGridContainer>

      <StyledGridContainer container>
        <StyledGridItem item xs={12}>
          <StyledBoxContainer>
            <StyledHeaderBox />
            <Typography variant="h2">Partnering</Typography>
          </StyledBoxContainer>
        </StyledGridItem>
        {<RenderForm formDataConfig={group3} />}
      </StyledGridContainer>

      <StyledGridContainer container>
        <StyledGridItem item xs={12}>
          <StyledBoxContainer>
            <StyledHeaderBox />
            <Typography variant="h2">Administration</Typography>
          </StyledBoxContainer>
        </StyledGridItem>
        {<RenderForm formDataConfig={adminGroup} />}
      </StyledGridContainer>

      <StyledGridContainer container>
        <StyledGridItem item xs={12}>
          <StyledBoxContainer>
            <StyledHeaderBox />
            <Typography variant="h2">File Close Out</Typography>
          </StyledBoxContainer>
        </StyledGridItem>
        {<RenderForm formDataConfig={fileCloseOut} />}
      </StyledGridContainer>

      {/*<div>{renderCount}</div>*/}
    </>
  );
}

export default Tombstone;
Tombstone.propTypes = {
  data: PropTypes.object
};
